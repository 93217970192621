import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

export interface ConfirmDialogModel {
  title: string;
  message: string;
  isHtml?: boolean;
  confirmMessage?: string;
  color?: string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
  styleUrls: ['./confirm-dialog.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
  ],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  isHtml: boolean = false;
  color: string = 'primary';
  confirmMessage?: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.isHtml = data.isHtml ?? this.isHtml;

    this.color = data.color ?? this.color;
    this.confirmMessage = data.confirmMessage ? data.confirmMessage : 'Yes';
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
