<h1 *ngIf="title" mat-dialog-title>{{ title }}</h1>

<ng-container *ngIf="message" class="apply-dialog">
  <div *ngIf="isHtml; else textMessage" mat-dialog-content [innerHTML]="message" class="message"></div>
  <ng-template #textMessage>
    <div mat-dialog-content>
      <p>{{ message }}</p>
    </div>
  </ng-template>
</ng-container>

<div mat-dialog-actions class="confirm-actions">
  <button id="confirm-dialog_button-cancel" mat-button (click)="onDismiss()">Cancel</button>
  <button id="confirm-dialog_button-confirm" mat-raised-button [color]="color" (click)="onConfirm()">
    {{ confirmMessage }}
  </button>
</div>
